import { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { AuthContext } from '../../contexts/AuthContext';
import { InputButton } from '../../components/common';
import './Login.scss';

function Login() {
    const { isAuthed, login } = useContext(AuthContext);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();

    if (isAuthed) return <Navigate to="/images" replace />;

    const initialFormValues: LoginFields = {
        email: '',
        password: '',
        remember: false
    };

    const handleSubmit = (values: LoginFields, formikHelpers: FormikHelpers<LoginFields>) => {
        setErrorMsg(undefined);
        login(values)
            .catch(() => setErrorMsg('Incorrect login credentials'))
            .finally(() => formikHelpers.setSubmitting(false));
    };

    return (
        <div className="login">
            <img src="/logo.png" alt="Timelapse Logo" />
            <h3>User Sign In</h3>
            <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <Form>
                        <div className="input-container">
                            <h5>Login</h5>
                            <Field name="email" type="text" />
                        </div>
                        <div className="input-container">
                            <h5>Password</h5>
                            <Field name="password" type="password" />
                        </div>
                        {/* eslint-disable-next-line */}
                        <label htmlFor="remember">
                            <Field id="remember" type="checkbox" name="remember" />
                            Remember me
                        </label>
                        {/* <Field type="checkbox" name="remember" label="Remember Me" /> */}
                        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
                        <InputButton type="submit" label="Log In" disabled={isSubmitting} />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Login;
