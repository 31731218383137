import { useEffect } from 'react';
import Calendar from 'react-calendar';
import DatePicker from 'react-datepicker';
import useMediaQuery from '../../../hooks/useMediaQuery';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import './InputCalendar.scss';

interface InputCalendarProps {
    /**
     * Calendar display type
     */
    type?: 'default' | 'dropdown';
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Selected date
     */
    value?: Date;
    /**
     * Disable dates before this date
     */
    minDate?: Date;
    /**
     * Disable dates after this date
     */
    maxDate?: Date;
    /**
     * On Change selected item
     */
    onSelectedValue?: (date: Date) => void;
    /**
     * If true, user can select dates using up/down arrow keys
     */
    arrowKeyNavigation?: boolean;
}

function InputCalendar({
    className = '',
    value = new Date(),
    minDate = new Date('1970-01-01'),
    maxDate = new Date(),
    type = 'default',
    onSelectedValue = () => {},
    arrowKeyNavigation = false
}: InputCalendarProps) {
    const { isMobileDevice } = useMediaQuery();

    // Attach listener on up and down arrow keys
    useEffect(() => {
        /**
         * Selects the date in the direction of arrow key pressed
         */
        function changeDate(event: KeyboardEvent) {
            const targetDate = new Date(value);

            switch (event.code) {
                case 'ArrowUp':
                    targetDate.setDate(targetDate.getDate() + 1);
                    break;
                case 'ArrowDown':
                    targetDate.setDate(targetDate.getDate() - 1);
                    break;
                default:
                    targetDate;
            }

            // Ignore comparing time
            targetDate.setHours(0, 0, 0, 0);
            minDate.setHours(0, 0, 0, 0);
            maxDate.setHours(0, 0, 0, 0);

            // Pass the selected date up onChangeDate
            const validDate = targetDate >= minDate && targetDate <= maxDate;
            if (validDate) onSelectedValue(targetDate);
        }

        if (arrowKeyNavigation) document.addEventListener('keydown', changeDate);
        return () => document.removeEventListener('keydown', changeDate);
    }, [value]);

    return (
        <div className={`input-calendar ${className}`}>
            {type === 'default' && !isMobileDevice() ? (
                <Calendar
                    key={value.toString()}
                    onChange={onSelectedValue}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    next2Label={null}
                    prev2Label={null}
                />
            ) : (
                <DatePicker
                    className={`input-calendar-mobile-custom ${className}`}
                    customInput={
                        <div className="icon-dropdown">
                            <span className="icon-dropdown__text">
                                {value.toLocaleDateString('vi-VI')}
                            </span>
                            <i className="bi bi-calendar-event" />
                        </div>
                    }
                    onChange={onSelectedValue}
                    selected={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    calendarClassName="input-calendar-mobile-custom__board"
                    popperClassName="input-calendar-mobile-custom__popper"
                />
            )}
        </div>
    );
}

export default InputCalendar;
