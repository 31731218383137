import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { SelectCameraDropdown } from '../../dropdowns';
import './Header.scss';

function Header() {
    return (
        <nav className="header">
            <Logo />
            <div className="header__right">
                <SelectCameraDropdown />
                <ProfileIcon />
            </div>
        </nav>
    );
}

/**
 * Timelapse logo that redirect to the Index page when clicked.
 */
function Logo() {
    return (
        <Link to="/">
            <img
                className="header__logo"
                src="/logo.png"
                alt="Timelapse Logo"
                width={400}
                height={175}
            />
        </Link>
    );
}

/**
 * Profile icon that shows a dropdown when clicked.
 */
function ProfileIcon() {
    const { logout } = useContext(AuthContext);

    return (
        <div className="profile-dropdown">
            <i className="profile-dropdown__image bi bi-person-circle" />
            <div className="profile-dropdown__content">
                <div onClick={logout}>Logout</div>
            </div>
        </div>
    );
}

export default Header;
