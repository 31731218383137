import { useContext, useMemo } from 'react';
import { CameraContext } from '../../../contexts/CameraContext';
import { sortInputOptionsUnderscoreLast } from '../../../utils';
import { Dropdown } from '../../common';

/**
 * Dropdown component for displaying and selecting the active camera.
 */
function SelectCameraDropdown() {
    const { cameras, selectedCamera, setSelectedCamera } = useContext(CameraContext);

    /**
     * Convert list of cameras into options format.
     */
    const dropdownOptions = useMemo(() => {
        const options = Object.values(cameras)
            .map((camera) => ({
                label: camera.name,
                value: camera.id?.toString()
            }));
        return sortInputOptionsUnderscoreLast(options);
    }, [cameras]);

    return (
        <Dropdown
            className="camera-dropdown"
            value={selectedCamera?.id?.toString()}
            options={dropdownOptions}
            onChange={(cameraId) => setSelectedCamera(cameras[+cameraId])}
            placeholder="Select Camera"
        />
    );
}

export default SelectCameraDropdown;
