import { useContext } from 'react';
import { CameraContext } from '../../contexts/CameraContext';
import { ContentWithSidebarLayout } from '../../components/layout';
import { ImageViewer, InputButton, InputCalendar, InputToggle } from '../../components/common';
import useMediaQuery from '../../hooks/useMediaQuery';
import ImageThumbnails from '../../components/thumbnails/ImageThumbnails/ImageThumbnails';
import './Images.scss';
import DebugWindow from '../../components/common/DebugWindow/DebugWindow';

function Images() {
    const { isMobileDevice, isTabletDevice } = useMediaQuery();
    const { firstImage, sessionCookies, selectedCamera } = useContext(CameraContext);

    const {
        images,
        selectedImage,
        selectedDate,
        fetchImage,
        updateSelectedDate,
        getImageUrl,
        getDownloadUrl,
        imageFetchLog,
        clearImageFetchLog,
    } = firstImage;

    const { cookies, isAutoRefresh, toggleAutoRefresh, isFillMode, toggleFillMode } =
        sessionCookies;
    const userCanDownloadImage = cookies.data?.features?.can_download_images;

    return (
        <ContentWithSidebarLayout
            className="images"
            leftSidebarContent={leftSidebarContent()}
            mainContent={mainContent()}
            rightSidebarContent={rightSidebarContent()}
        />
    );

    function leftSidebarContent() {
        return (
            <>
                <InputCalendar
                    className={`calendar ${isMobileDevice() && 'input-calendar--mobile'}`}
                    type={isTabletDevice() ? 'dropdown' : 'default'}
                    onSelectedValue={updateSelectedDate}
                    value={new Date(selectedDate)}
                    minDate={selectedCamera && new Date(selectedCamera.minDate)}
                    maxDate={selectedCamera && new Date(selectedCamera.maxDate)}
                    arrowKeyNavigation
                />
                {!isMobileDevice() && (
                    <ImageThumbnails
                        type="vertical"
                        images={images}
                        onClick={fetchImage}
                        getImageUrl={getImageUrl}
                        selectedImage={selectedImage}
                        arrowKeyNavigation
                    />
                )}
            </>
        );
    }

    function mainContent() {
        console.log('REACT_APP_IMAGE_FETCH_DEBUG', process.env.REACT_APP_IMAGE_FETCH_DEBUG);
        return (
            <div className="images__content">
                {selectedImage?.src && (
                    <ImageViewer
                        imageSrc={selectedImage.src}
                        isFillMode={isFillMode}
                        isDisabled={!userCanDownloadImage}
                        showFullscreenIcon
                    />
                )}
                {isMobileDevice() && (
                    <ImageThumbnails
                        type="horizontal"
                        images={images}
                        onClick={fetchImage}
                        selectedImage={selectedImage}
                        getImageUrl={getImageUrl}
                    />
                )}
                {process.env.REACT_APP_IMAGE_FETCH_DEBUG === 'true' && (
                    <DebugWindow log={imageFetchLog} clear={clearImageFetchLog} />
                )}
            </div>
        );
    }

    function rightSidebarContent() {
        /**
         * Downloads the currently viewed image.
         * If CORS, the browser opens it in a new tab instead for security purposes.
         * Read more: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download
         */
        const handleImageDownload = async () => {
            if (!selectedImage) return;

            const downloadUrl = getDownloadUrl(selectedImage);
            if (!downloadUrl) throw new Error('Cannot download image');

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = ''; // filename can only be declared server-side
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return (
            <div className="images__rsc">
                <InputToggle
                    className="images__rsc__toggle"
                    label="Auto Refresh"
                    checked={isAutoRefresh}
                    onChange={() => toggleAutoRefresh()}
                    subtitle="Enable auto refresh to automatically load the most recent photo into image viewer"
                />
                <InputToggle
                    className="images__rsc__toggle"
                    label="Fill Mode"
                    checked={isFillMode}
                    onChange={() => toggleFillMode()}
                    subtitle="Enable fill mode to view photo without margins on the sides"
                />
                {process.env.REACT_APP_IMAGE_FETCH_DEBUG === 'true' && (
                    <p style={{ fontSize: 12 }}>
                        {selectedImage?.src}
                    </p>
                )}

                <InputButton
                    className="images__rsc__download"
                    type="button"
                    label="Download Image"
                    onClick={handleImageDownload}
                    disabled={!userCanDownloadImage || !selectedImage}
                />
            </div>
        );
    }
}

export default Images;
