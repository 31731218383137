import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { ImageSize } from '../types/enums';
import { formatDateToTypeN, timer } from '../utils';
import { SessionCookieState } from './useSessionCookies';

export interface ImageState {
    /**
     * The current viewed image
     */
    selectedImage?: Image;
    /**
     * Function to fetch and/or set images
     */
    fetchImage: (image: Image) => Promise<void>;
    /**
     * An array of images from camera
     */
    images: Image[];
    /**
     * Return path url of image
     */
    getImageUrl: (image: Image, imageSize?: ImageSize) => string;
    /**
     * Return path url of image
     */
    getDownloadUrl: (image: Image, imageSize?: ImageSize) => string;
    /**
     * The selected date formatted as YYYY-MM-DD
     */
    selectedDate: string;
    /**
     * Set the date. Converts a Date object to the formatted date
     */
    updateSelectedDate: (date: Date) => void;
    /**
     * History log of image fetches. Used for debugging.
     */
    imageFetchLog: string[];
    /**
     * Clear the image fetch log
     */
    clearImageFetchLog: () => void;
}

/**
 * Used to get images and image-related data from the API
 */
const useFetchImages = (
    sessionCookies: SessionCookieState,
    camera?: Camera,
    doPolling: boolean = false
): ImageState => {
    const todaysDate = formatDateToTypeN(new Date());

    const { isAutoRefresh, refreshCookies } = sessionCookies;
    const [cameraUrl, setCameraUrl] = useState<string>();
    const [images, setImages] = useState<Image[]>([]);
    const [selectedImage, setSelectedImage] = useState<Image | undefined>();
    const [selectedDate, setSelectedDate] = useState<string>(todaysDate);
    const [imageURLsloaded, setImageURLsLoaded] = useState<string[]>([]);
    const [pollUrl, setPollUrl] = useState<string>();
    const [pollTimer, setPollTimer] = useState<NodeJS.Timer>();
    const [imageFetchLog, setImageFetchLog] = useState<string[]>([]);

    const onTodaysDate = selectedDate === todaysDate;
    const canPollImage = doPolling && onTodaysDate;

    /**
     * Returns a URL string to get Camera images
     */
    function updateCameraUrl(date: string) {
        if (camera) {
            const url = `${camera.photosUrl}${date}`;
            if (url !== cameraUrl) {
                setCameraUrl(url);
            }
        } else {
            resetStates();
        }
    }

    // When camera changes - reset date to current date and update camera url
    useEffect(() => {
        if (camera) {
            const selectedDateParsed = Date.parse(selectedDate);
            const cameraMaxDateParsed = Date.parse(camera?.maxDate);
            const cameraMinDateParsed = Date.parse(camera?.minDate);
            if (selectedDateParsed < cameraMinDateParsed) {
                setSelectedDate(formatDateToTypeN(new Date(camera.minDate)));
                updateCameraUrl(selectedDate);
                return;
            }
            if (selectedDateParsed > cameraMaxDateParsed) {
                setSelectedDate(formatDateToTypeN(new Date(camera.maxDate)));
                updateCameraUrl(selectedDate);
                return;
            }
            updateCameraUrl(selectedDate);
        }
    }, [camera]);

    // When date changes - update camera url
    useEffect(() => {
        updateCameraUrl(selectedDate);
    }, [selectedDate]);

    // When camera url changes, fetch new list of images
    useEffect(() => {
        if (!onTodaysDate) clearPollTimer();
        if (cameraUrl) fetchCameraImages(cameraUrl);
    }, [cameraUrl]);

    /**
     * Creates a timer for polling which will fetch a new list of images after timeout
     */
    const createPollTimer = (url: string, msFromNow: number) => {
        clearPollTimer();
        const nextRefreshDate = new Date();
        nextRefreshDate.setMilliseconds(nextRefreshDate.getMilliseconds() + msFromNow);
        setPollTimer(timer(nextRefreshDate, () => fetchCameraImages(url), true));
    };

    /**
     * When polling param changes, reinitiate polling depending
     */
    useEffect(() => {
        clearPollTimer();
        if (canPollImage && pollUrl) {
            const defaultTimerMs = 2000;
            createPollTimer(pollUrl, defaultTimerMs);
        }
    }, [doPolling]);

    /**
     * Fetch an updated list of camera images
     */
    const fetchCameraImages = (url: string) => {
        if (!url) return;
        axios
            .get(url)
            .then(({ status, data }) => {
                if (status === 200) {
                    readAPIResponse(data);
                    refreshCookies();
                } else {
                    throw new Error('Response not 200');
                }
            })
            .catch((err: AxiosError) => {
                console.error(err);

                if (err.response?.status === 404) {
                    resetStates();
                }
            });

        /**
         * Reads the response from the API and set states
         */
        function readAPIResponse(res: APIImagesResponse) {
            const { photos, action, refresh, pollUrl: resPollUrl } = res;

            // Set the new image refresh date
            if (canPollImage) {
                setPollUrl(resPollUrl);
                createPollTimer(resPollUrl, refresh.nextMs);
            }

            // Update images state
            if (action === 'append') {
                // Is from polling; append to current data
                setImages((prevImages) => [...prevImages, ...photos]);
            } else if (action === 'replace') {
                // Is from camera/date switching. Replace current data
                setImages(photos);
            }

            // Set image to see in viewer
            const lastImage = photos.at(photos.length - 1);
            let imageToLoad = lastImage;
            if (!onTodaysDate) {
                const middayImage = photos.find((img) => img.filename.includes('1200'));
                if (middayImage) imageToLoad = middayImage;
            }
            // If there is an image to load
            if (imageToLoad !== undefined) {
                fetchImage(imageToLoad);
            }
        }
    };

    // State to keep track of the current preloading image; we will mutate this below.
    // eslint-disable-next-line
    let [preloadingImage, setPreloadingImage] = useState<HTMLImageElement>();

    /**
     * 
     */
    const resetPreloading = () => {
        // Stops fetching of previous image
        // Requires a state mutation to stop the fetch immediately
        // if (preloadingImage) {
            setImageFetchLog((prev) => [...prev, `[STOP PRELOAD]`]);
            setPreloadingImage({ ...preloadingImage, src: '' } as HTMLImageElement);
            setPreloadingImage(undefined);
        // }
    };

    /**
     * Fetches and sets the selected image. Preloads its following image
     */
    const fetchImage = async (image: Image) => {
        setImageFetchLog((prev) => [...prev, "------------------------------", `[SELECTED] ${image.filename}`]);

        resetPreloading();

        // Ready to preload next image
        const nextImage = getImageAfter(image);
        const nextImageFullUrl = nextImage && getImageUrl(nextImage, ImageSize.FULLSIZE);

        // If full size image already loaded, set the full size image
        const fullUrl = getImageUrl(image, ImageSize.FULLSIZE);
        if (imageURLsloaded.includes(fullUrl)) {
            setImageFetchLog((prev) => [...prev, `[EXISTED] ${image.filename}`]);
            setSelectedImage({ ...image, src: fullUrl });
            nextImageFullUrl && loadSingleImage(nextImageFullUrl);
        } else {
            // Fetch and set reduced image (large)
            const largeUrl = getImageUrl(image, ImageSize.LARGE);
            if (largeUrl) {
                loadSingleImage(largeUrl, true).then(() => {
                    if (fullUrl) {
                        // Then fetch and load high res image
                        loadSingleImage(fullUrl, true).then(() => {
                            // Download next fullsize image in background
                            nextImageFullUrl && loadSingleImage(nextImageFullUrl);
                        });
                    }
                });
            }
        }

        /**
         * Helper function to load an image into local cache
         */
        async function loadSingleImage(imageUrl: string, setImage: boolean = false) {
            let [size, name] = imageUrl.split('/').slice(-2);
            size = size.split('-').pop() || '';
            setImageFetchLog((prev) => [...prev, `[REQUESTING] ${size} ${name}`]);

            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageUrl;
                img.loading = 'eager';
                // @ts-ignore
                // eslint-disable-next-line
                img.fetchPriority = "high";
                img.onload = () => {
                    setImageURLsLoaded([...imageURLsloaded, imageUrl]);
                    setImage && setSelectedImage({ ...image, src: imageUrl });
                    setImageFetchLog((prev) => [...prev, `[SUCCESSFUL] ${size} ${name}`]);
                    return resolve(true);
                };
                img.onerror = (e) => {
                    setImageFetchLog((prev) => [...prev, `[FAILED] ${size} ${name}`]);
                    console.log('Failed to prefetch image: ', JSON.stringify(e));
                    return reject();
                };
                resetPreloading();
            });
        }
    };

    /**
     * Returns the image after the image argument
     */
    const getImageAfter = (image: Image) => {
        const currImgIndex = images.findIndex((img) => img.filename === image?.filename);
        const nextImageIndex = currImgIndex - 1;
        const isValidIndex = currImgIndex > 0;
        return isValidIndex ? images[nextImageIndex] : null;
    };

    /**
     * Return a URL string to an image
     */
    const getImageUrl = (image: Image, imageSize: ImageSize = ImageSize.THUMBNAIL) => {
        const apiImageUrlPrefix = camera?.imageUrlPrefix;
        if (!apiImageUrlPrefix) return '';
        return apiImageUrlPrefix + image[imageSize];
    };

    /**
     * Return a URL string to a download link of image
     */
    const getDownloadUrl = (image: Image, imageSize: ImageSize = ImageSize.FULLSIZE) => {
        const apiDownloadUrlPrefix = camera?.downloadImageUrlPrefix;
        if (!apiDownloadUrlPrefix) return '';
        return apiDownloadUrlPrefix + image[imageSize];
    };

    /**
     * Reset polling states
     */
    const clearPollTimer = () => {
        clearInterval(pollTimer);
    };

    /**
     * Reset all states
     */
    const resetStates = () => {
        setCameraUrl(undefined);
        setImages([]);
        setSelectedImage(undefined);
        setSelectedDate(todaysDate);
        setImageURLsLoaded([]);
        setPollUrl(undefined);
        clearPollTimer();
    };

    /**
     * Formats and sets a selected date state
     */
    const updateSelectedDate = (date: Date) => {
        const formattedDate = formatDateToTypeN(date);
        setSelectedDate(formattedDate);
    };

    return {
        selectedImage,
        fetchImage,
        getImageUrl,
        getDownloadUrl,
        selectedDate,
        updateSelectedDate,
        images,
        imageFetchLog,
        clearImageFetchLog: () => setImageFetchLog([]),
    };
};

export default useFetchImages;
