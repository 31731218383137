import { useState, useEffect } from 'react';
import { formatSecondsToMMSS } from '../utils';

/**
 * Custom hooks for VideoPlayer component
 */
const useVideoPlayer = (videoRef: React.MutableRefObject<HTMLVideoElement>) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isLooping, setIsLooping] = useState<boolean>(false);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<string>(formatSecondsToMMSS(0));
    const [progress, setProgress] = useState<number>(0);
    const [speed, setSpeed] = useState<number>(1);
    const duration = formatSecondsToMMSS(videoRef.current.duration);
    const frameTime = 1 / 25; // number of frames-per-second of video

    // Disable right-click context menu
    useEffect(() => {
        videoRef.current.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });
    }, []);

    // Recreate and re-register keydown listener on each isPlaying change
    useEffect(() => {
        // Handle keyboard key presses
        function handleKeyPress(event: KeyboardEvent) {
            if (event.code === 'ArrowLeft') {
                // Move frame backward
                setIsPlaying(false);
                videoRef.current.currentTime = Math.max(
                    0,
                    videoRef.current.currentTime - frameTime
                );
            } else if (event.code === 'ArrowRight') {
                // Move frame forward
                setIsPlaying(false);
                videoRef.current.currentTime = Math.min(
                    videoRef.current.duration,
                    videoRef.current.currentTime + frameTime
                );
            } else if (event.code === 'Space') {
                // Toggles play state
                togglePlay();
            }
        }

        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [isPlaying]);

    // Handle when isPlaying state changes
    useEffect(() => {
        isPlaying ? videoRef.current.play() : videoRef.current.pause();
    }, [isPlaying, videoRef]);

    // Handle when isMuted state changes
    useEffect(() => {
        videoRef.current.muted = isMuted;
    }, [isMuted, videoRef]);

    /**
     * Toggle video playback
     */
    const togglePlay = () => {
        setIsPlaying(!isPlaying);
    };

    /**
     * Toggle video loop mode
     */
    const toggleLoop = () => {
        setIsLooping(!isLooping);
    };

    /**
     * Toggle audio
     */
    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    /**
     * Toggle fullscreen mode
     */
    const enableFullscreen = () => {
        videoRef.current.requestFullscreen();
    };

    /**
     * Handle time update of video progress bar
     */
    const handleOnTimeUpdate = () => {
        let newProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
        if (Number.isNaN(newProgress)) newProgress = 0;
        setCurrentTime(formatSecondsToMMSS(videoRef.current.currentTime));
        setProgress(newProgress);
    };

    /**
     *  Update video progress when moving bar manually
     */
    const changeVideoProgress = (event: React.ChangeEvent<HTMLInputElement>) => {
        const manualChange = Number(event.target.value);
        videoRef.current.currentTime = (videoRef.current.duration / 100) * manualChange;
        setProgress(manualChange);
    };

    /**
     * Update video playback speed
     */
    const changeVideoSpeed = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSpeed = Number(event.target.value);
        videoRef.current.playbackRate = newSpeed;
        setSpeed(newSpeed);
    };

    return {
        playerState: {
            isPlaying,
            isLooping,
            isMuted,
            progress,
            speed,
            currentTime,
            duration
        },
        togglePlay,
        toggleLoop,
        toggleMute,
        enableFullscreen,
        handleOnTimeUpdate,
        changeVideoProgress,
        changeVideoSpeed
    };
};

export default useVideoPlayer;
