import { CookiesProvider } from 'react-cookie';
import { useAuthState, AuthContext } from '../contexts/AuthContext';
import { useCameraState, CameraContext } from '../contexts/CameraContext';
import useSessionCookies from '../hooks/useSessionCookies';
import Routing from './Routing';
import Header from './layout/Header/Header';

function App() {
    const sessionCookies = useSessionCookies();
    const authState = useAuthState(sessionCookies);
    const cameraState = useCameraState(sessionCookies, authState.isAuthed);

    return (
        <CookiesProvider>
            <AuthContext.Provider value={authState}>
                <CameraContext.Provider value={cameraState}>
                    {authState.isAuthed && <Header />}
                    <Routing />
                </CameraContext.Provider>
            </AuthContext.Provider>
        </CookiesProvider>
    );
}

export default App;
