import { CSSProperties } from 'react';
import './InputButton.scss';

interface InputButtonProps {
    /**
     * Button type
     */
    type?: 'button' | 'submit' | 'reset';
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Label text inside button
     */
    label?: string;
    /**
     * Additional inline styles
     */
    style?: CSSProperties;
    /**
     * If true, button is unusable and un-clickable
     */
    disabled?: boolean;
    /**
     * The function to be called when the button is clicked
     */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * A styled button component.
 */
function InputButton({
    type = 'button',
    className = '',
    label,
    style,
    disabled,
    onClick
}: InputButtonProps) {
    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={`input-button ${className}`}
            style={style}
            disabled={disabled}
            onClick={onClick}
        >
            {label}
        </button>
    );
}

export default InputButton;
