import axios from 'axios';
import { createContext, useState } from 'react';
import { SessionCookieState } from '../hooks/useSessionCookies';

export interface AuthState {
    isAuthed?: boolean;
    checkAuth: () => void;
    login: (e: LoginFields) => Promise<void>;
    logout: () => void;
}

export const AuthContext = createContext<AuthState>({
    isAuthed: undefined,
    checkAuth: () => {},
    login: () => Promise.resolve(),
    logout: () => {}
});

export const useAuthState = (sessionCookies: SessionCookieState): AuthState => {
    const [authed, setAuthed] = useState<boolean>();
    const { cookies, setCookies, clearCookies } = sessionCookies;
    const API = process.env.REACT_APP_BACKEND_API!;

    // Checks if the user is still authenicated to use the app
    const checkAuth = () => {
        const sessionNotExpired = new Date() < new Date(cookies.session?.expires_at);
        if (sessionNotExpired) {
            setAuthed(true);
        } else {
            logout();
        }
    };

    // Initiate login
    async function login({ email, password, remember }: LoginFields) {
        const url = `${API}/session/create`;
        const req = {
            login: email,
            password
        };

        return axios.post(url, req).then(({ status, data }) => {
            if (status === 200) {
                const response: CookieData = data;
                setCookies(response, remember);
                setAuthed(true);
            }
        });
    }

    // Ends session on backend
    function logout() {
        // Instantly end session on app
        clearCookies();
        setAuthed(false);

        // End session in API
        const apiSignOutUrl = cookies.data?.signOutUrl;
        if (apiSignOutUrl) {
            axios
                .post(apiSignOutUrl)
                .then(() => console.info('Success'))
                .catch(() => console.error('Error'));
        }
    }

    return { isAuthed: authed, checkAuth, login, logout };
};
