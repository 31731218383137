import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import useFetchImages, { ImageState } from '../hooks/useFetchImages';
import { SessionCookieState } from '../hooks/useSessionCookies';
import { timer } from '../utils';

export interface CameraState {
    /**
     * List of available cameras and their details
     */
    cameras: Cameras;
    /**
     * The selected camera
     */
    selectedCamera?: Camera;
    /**
     * Set the selected camera
     */
    setSelectedCamera: (camera: Camera) => void;
    /**
     * State management of first image (On Images and Comparison page)
     */
    firstImage: ImageState;
    /**
     * State management of second image (On Comparison page)
     */
    secondImage: ImageState;

    sessionCookies: SessionCookieState;
}

export const CameraContext = createContext<CameraState>({
    cameras: {},
    selectedCamera: undefined,
    setSelectedCamera: () => {},
    firstImage: {} as ImageState,
    secondImage: {} as ImageState,
    sessionCookies: {} as SessionCookieState
});

/**
 * Global state for managing and selecting the available cameras.
 */
export const useCameraState = (
    sessionCookies: SessionCookieState,
    isAuthed?: boolean
): CameraState => {
    const { cookies, refreshCookies } = sessionCookies;
    const [cameras, setCameras] = useState<Cameras>({} as Cameras);
    const [selectedCamera, setSelectedCamera] = useState<Camera>();
    const [canPollCamera, setCanPollCamera] = useState<boolean>(false);

    // Used for Images/Comparison page
    const isOnImageViewer = window.location.pathname === '/';
    const firstImage = useFetchImages(sessionCookies, selectedCamera, isOnImageViewer);
    // Used for Comparison page
    const secondImage = useFetchImages(sessionCookies, selectedCamera);
    // Used for Videos page

    // Fetch cameras on init only if authenticated
    useEffect(() => {
        if (isAuthed) {
            setCanPollCamera(true);
        } else {
            // Reset states
            setCameras({} as Cameras);
            setSelectedCamera(undefined);
            setCanPollCamera(false);
        }
    }, [isAuthed]);

    // Refreshes the list of cameras when camera polling timer ends
    useEffect(() => {
        if (canPollCamera) {
            fetchCameras();
        }
    }, [canPollCamera]);

    /**
     * Fetch an updated list of available cameras from the API
     */
    const fetchCameras = () => {
        const apiCamerasUrl = cookies.data?.camerasUrl;
        axios
            .get(apiCamerasUrl)
            .then(({ status, data }) => {
                if (status === 200) {
                    readAPIResponse(data);
                    refreshCookies();
                } else {
                    throw new Error('Response not 200');
                }
            })
            .catch((err) => console.error(err));

        /**
         * Reads the response from the API and set states
         */
        function readAPIResponse(res: APICameraResponse) {
            // Set the camera list
            const cameraArr: Camera[] = res.cameras;
            const cameraList: Cameras = cameraArr.reduce(
                (map: Cameras, cam: Camera) => ({
                    ...map,
                    [cam.id]: cam
                }),
                {}
            );
            setCameras(cameraList);

            // Set existing camera; if none, selected first in list
            if (selectedCamera && cameraList[selectedCamera.id]) {
                setSelectedCamera(selectedCamera);
            } else {
                setSelectedCamera(cameraArr[0]);
            }

            // Set the new camera poll date
            setCanPollCamera(false);
            const nextRefreshDate = new Date();
            nextRefreshDate.setMilliseconds(nextRefreshDate.getMilliseconds() + res.refresh.nextMs);
            timer(nextRefreshDate, () => setCanPollCamera(true));
        }
    };

    return {
        cameras,
        selectedCamera,
        setSelectedCamera,
        firstImage,
        secondImage,
        sessionCookies
    };
};
