import { useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { NavigationDropdown } from '../../dropdowns';
import './ContentWithSidebarLayout.scss';

interface ContentWithSidebarLayoutProps {
    className?: string;
    leftSidebarContent?: React.ReactNode;
    mainContent?: React.ReactNode;
    rightSidebarContent?: React.ReactNode;
}

/**
 * A layout template for content with a sidebar on the left.
 * @param param.className Additional classnames for container div
 * @param param.leftSidebarContent Contents for sidebar on the left side
 * @param param.mainContent Contents of the main column
 * @param param.rightSidebarContent Contents for sidebar on the right side
 */
function ContentWithSidebarLayout({
    className = '',
    leftSidebarContent,
    mainContent,
    rightSidebarContent
}: ContentWithSidebarLayoutProps) {
    const { isMobileDevice } = useMediaQuery();
    const [isRightSidebarVisible, setIsRightSidebarVisible] = useState<boolean>(!isMobileDevice());

    return (
        <div className={`cws ${className} ${isMobileDevice() ? 'cws--column' : 'cws--row'}`}>
            <div className={`cws__sidebar ${isMobileDevice() ? 'cws--row' : 'cws--column'}`}>
                <NavigationDropdown />
                {leftSidebarContent}
            </div>
            <div className="cws__content">{mainContent}</div>
            <div className={`cws__modal ${isRightSidebarVisible && 'cws__modal--active'}`}>
                <i
                    className="cws__modal__icon bi bi-gear modal-bg"
                    onClick={() => setIsRightSidebarVisible(!isRightSidebarVisible)}
                />
                {isRightSidebarVisible && (
                    <div className="cws__sidebar modal-bg">{rightSidebarContent}</div>
                )}
            </div>
            {
                /* An empty div to imitate the right sidebar space on non-Mobile Devices */
                isRightSidebarVisible && !isMobileDevice() && (
                    <div className="cws__sidebar modal-bg" />
                )
            }
        </div>
    );
}

export default ContentWithSidebarLayout;
