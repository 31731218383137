import { useContext } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { InputCalendar } from '../../components/common';
import ImageThumbnails from '../../components/thumbnails/ImageThumbnails/ImageThumbnails';
import { ContentWithSidebarLayout } from '../../components/layout';
import { CameraContext } from '../../contexts/CameraContext';
import useMediaQuery from '../../hooks/useMediaQuery';
import './Comparison.scss';

function Comparison() {
    const { isMobileDevice, isTabletDevice } = useMediaQuery();
    const { firstImage, secondImage, selectedCamera } = useContext(CameraContext);

    const {
        selectedImage: imageLeft,
        selectedDate: imageLeftDate,
        images: imagesleft,
        fetchImage: fetchImageLeft,
        updateSelectedDate: updateSelectedDateLeft,
        getImageUrl
    } = firstImage;

    const {
        selectedImage: imageRight,
        selectedDate: imageRightDate,
        images: imagesRight,
        fetchImage: fetchImageRight,
        updateSelectedDate: updateSelectedDateRight
    } = secondImage;

    return (
        <ContentWithSidebarLayout
            className="comparison"
            leftSidebarContent={leftSidebarContent()}
            mainContent={mainContent()}
            rightSidebarContent={rightSidebarContent()}
        />
    );

    function leftSidebarContent() {
        return (
            <>
                <InputCalendar
                    type={isTabletDevice() ? 'dropdown' : 'default'}
                    onSelectedValue={updateSelectedDateLeft}
                    value={new Date(imageLeftDate)}
                    minDate={selectedCamera && new Date(selectedCamera.minDate)}
                    maxDate={selectedCamera && new Date(selectedCamera.maxDate)}
                    arrowKeyNavigation
                />
                {!isMobileDevice() && leftInputThumbnail('vertical')}
            </>
        );
    }

    function mainContent() {
        return (
            <div className="comparison__main-content">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: isMobileDevice() ? 'fit-content' : '100%'
                    }}
                >
                    <ReactCompareSlider
                        position={50}
                        itemOne={
                            <ReactCompareSliderImage
                                alt="Left Photo"
                                src={imageLeft?.src}
                                loading="lazy"
                            />
                        }
                        itemTwo={
                            <ReactCompareSliderImage
                                alt="Right Photo"
                                src={imageRight?.src}
                                loading="lazy"
                            />
                        }
                        style={{ flexGrow: 1, width: '100%' }}
                    />
                </div>
                {isMobileDevice() && (
                    <div className="comparison__main-content__thumbnails--mobile">
                        <div style={{ paddingLeft: 20, paddingTop: 20 }}>
                            <h4 className="title title--mobile">Image 1</h4>
                        </div>
                        {leftInputThumbnail('horizontal')}
                        <div
                            style={{
                                display: 'flex',
                                height: 'auto',
                                justifyContent: 'space-between',
                                paddingRight: 20,
                                paddingLeft: 20,
                            }}
                        >
                            <h4 className="title title--mobile">Image 2</h4>
                            {rightCalendar('calendar--mobile calendar--b')}
                        </div>
                        {rightInputThumbnail('horizontal')}
                    </div>
                )}
            </div>
        );
    }

    function rightSidebarContent() {
        return (
            !isMobileDevice() && (
                <>
                    <h4 className="title">Image 2</h4>
                    {rightCalendar()}
                    {rightInputThumbnail('vertical')}
                </>
            )
        );
    }

    function leftInputThumbnail(orientation: 'vertical' | 'horizontal') {
        return (
            <ImageThumbnails
                name="leftThumbnails"
                type={orientation}
                images={imagesleft}
                onClick={fetchImageLeft}
                getImageUrl={getImageUrl}
                selectedImage={imageLeft}
                arrowKeyNavigation
            />
        );
    }

    function rightInputThumbnail(orientation: 'vertical' | 'horizontal') {
        return (
            <ImageThumbnails
                name="rightThumbnails"
                type={orientation}
                images={imagesRight}
                onClick={fetchImageRight}
                getImageUrl={getImageUrl}
                selectedImage={imageRight}
            />
        );
    }

    function rightCalendar(className?: string) {
        return (
            <InputCalendar
                className={className}
                type={isTabletDevice() ? 'dropdown' : 'default'}
                onSelectedValue={updateSelectedDateRight}
                value={new Date(imageRightDate)}
                minDate={selectedCamera && new Date(selectedCamera.minDate)}
                maxDate={selectedCamera && new Date(selectedCamera.maxDate)}
            />
        );
    }
}

export default Comparison;
