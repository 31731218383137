import { useRef } from 'react';
import ReactDropdown from 'react-select';
import './Dropdown.scss';

interface DropdownProps extends InputWithOptionsProps {
    /**
     * Additional classnames
     */
    className?: string;
    /**
     * Placeholder text inside dropdown
     */
    placeholder?: string;
    /**
     * Selected item; can set default item selected
     */
    value?: string;
}

/**
 * A Dropdown component wrapped around react-dropdown
 */
function Dropdown({ className = '', value, options = [], placeholder, onChange }: DropdownProps) {
    const dropdownRef = useRef<any>(undefined);

    const onScrollToIndex = () => {
        setTimeout(() => {
            const menus = dropdownRef.current.props.options;
            const selectedItem = dropdownRef.current.props.value;
            const selectedItemIndex = menus.findIndex(
                (item: { label: string; value: string }) => item.value === selectedItem.value
            );

            const scrollToIndex = () => {
                const numberItemInMenuPage = 4;
                const numberOfItemAfter = (menus.length - 1) - selectedItemIndex;
                if (numberOfItemAfter > numberItemInMenuPage) {
                    return selectedItemIndex + numberItemInMenuPage;
                } else {
                    return selectedItemIndex + numberOfItemAfter;
                }
            };

            const selectedEl = document.querySelector(`div[id*="option-${scrollToIndex()}"]`);
            if (selectedEl) {
                selectedEl.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start'
                });
            }
        }, 15);
    };

    return (
        <ReactDropdown
            ref={dropdownRef}
            className={`reactdropdown ${className}`}
            styles={{
                menu: (provided, state) => ({
                    ...provided,
                    zIndex: 2
                })
            }}
            options={options}
            value={options.find((option) => option.value === value)}
            placeholder={placeholder ?? ''}
            onChange={(e) => onChange && e && onChange(e.value)}
            onMenuOpen={onScrollToIndex}
        />
    );
}

export default Dropdown;
