import axios from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

export interface SessionCookieState {
    /**
     * Cookies stored on the browser
     */
    cookies: {
        /**
         * API Response
         */
        data: CookieData;
        /**
         * API Response.session
         */
        session: CookieSession;
        /**
         * App settings for user
         */
        settings: CookieSettings;
    };
    /**
     * Set cookies after receiving a successful response from the API
     */
    setCookies: (apiResponse: CookieData, rememberMe?: boolean) => void;
    /**
     * Updates existing cookies
     */
    refreshCookies: () => void;
    /**
     * Clears all cookies from browser
     */
    clearCookies: () => void;
    /**
     * State to track if new images should be instantly viewed in the viewer
     */
    isAutoRefresh: boolean;
    /**
     * Toggle auto-refresh state
     */
    toggleAutoRefresh: () => void;
    /**
     * State to track if the viewed image is fill mode or not
     */
    isFillMode: boolean;
    /**
     * Toggle fill mode state
     */
    toggleFillMode: () => void;
}

/**
 * State management for cookies.
 */
const useSessionCookies = (): SessionCookieState => {
    const [cookies, setCookie, removeCookie] = useCookies(['data', 'session', 'settings']);
    const [isAutoRefresh, setIsAutoRefresh] = useState<boolean>(cookies.settings?.isAutoRefresh);
    const [isFillMode, setIsFillMode] = useState<boolean>(cookies.settings?.isFillMode);

    // Set initial state values when cookie is created
    useEffect(() => {
        if (isAutoRefresh === undefined) setIsAutoRefresh(true);
        if (isFillMode === undefined) setIsFillMode(true);
    }, []);

    // When any setting changes, update cookie
    useEffect(() => {
        refreshCookies();
    }, [isAutoRefresh, isFillMode]);

    /**
     * Save data as cookies in browser
     */
    const setCookies = (apiResponse: CookieData, remember?: boolean) => {
        const rememberMe = remember || cookies?.settings?.rememberMe;
        const expiryTime = apiResponse?.session?.expires_at || cookies?.session?.expires_at;
        const expiryDate = rememberMe ? new Date(expiryTime) : undefined;
        const cookieOptions = { path: '/', expires: expiryDate };
        setCookie('data', apiResponse, cookieOptions);
        setCookie('session', apiResponse.session, cookieOptions);
        const settings = {
            rememberMe,
            isAutoRefresh,
            isFillMode
        };
        setCookie('settings', settings, cookieOptions);
    };

    /**
     * Refreshes session cookie data and expiry
     */
    const refreshCookies = async () => {
        const apiRefreshUrl = cookies?.data?.refreshUrl;
        if (!apiRefreshUrl) return;
        axios.get(apiRefreshUrl).then(({ status, data }) => {
            if (status === 200) {
                const response: CookieData = data;
                setCookies(response);
            }
        });
    };

    /**
     * Clears all cookies
     */
    const clearCookies = () => {
        removeCookie('data');
        removeCookie('session');
        removeCookie('settings');
    };

    /**
     * Toggle auto-refresh state. If true, any new incoming images are displayed in the viewer
     */
    const toggleAutoRefresh = () => setIsAutoRefresh((prev) => !prev);

    /**
     * Toggle fill mode state. If true, the viewer shows images without margins on the side
     */
    const toggleFillMode = () => setIsFillMode((prev) => !prev);

    return {
        cookies: {
            data: cookies.data,
            session: cookies.session,
            settings: cookies.settings
        },
        setCookies,
        refreshCookies,
        clearCookies,
        isAutoRefresh,
        toggleAutoRefresh,
        isFillMode,
        toggleFillMode
    };
};

export default useSessionCookies;
