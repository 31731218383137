import { Routes, Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import Comparison from '../pages/Comparison/Comparison';
import Login from '../pages/Login/Login';
import Images from '../pages/Images/Images';
import NotFound from '../pages/NotFound/NotFound';
import Videos from '../pages/Videos/Videos';

/**
 * List of routes used in the app.
 */
export const routes: RouteConfig[] = [
    {
        path: '/login',
        name: 'Login',
        exact: true,
        Component: Login,
        secured: false
    },
    {
        path: '/',
        name: 'Images',
        exact: true,
        Component: Images,
        secured: true
    },
    {
        path: '/comparison',
        name: 'Comparison',
        exact: true,
        Component: Comparison,
        secured: true
    },
    {
        path: '/videos/*',
        name: 'Videos',
        exact: true,
        Component: Videos,
        secured: true
    },
    {
        path: '*',
        name: 'NoMatch',
        exact: false,
        Component: NotFound,
        secured: false
    }
];

/**
 * Map over and render routes + handle navigation.
 */
function Routing() {
    return (
        <Routes>
            {routes.map(({ path, Component, secured }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <RequireAuth secured={secured} redirectTo={path}>
                            <Component />
                        </RequireAuth>
                    }
                />
            ))}
        </Routes>
    );
}

export default Routing;
