import { useEffect, useState } from 'react';
import { ScreenDevice } from '../types/enums';

/**
 * Used to determine the screen device based off the pixel-width of the screen
 */
const useMediaQuery = () => {
    const [screenDevice, setScreenDevice] = useState<ScreenDevice>(determineScreenSize());

    // Determines the screen device
    function determineScreenSize() {
        const screenWidth = window.innerWidth;
        switch (true) {
            case screenWidth < ScreenDevice.MOBILE:
                return ScreenDevice.MOBILE;
            case screenWidth < ScreenDevice.TABLET:
                return ScreenDevice.TABLET;
            default:
                return ScreenDevice.DESKTOP;
        }
    }

    const handleResize = () => setScreenDevice(determineScreenSize());
    const isDesktopDevice = () => screenDevice === ScreenDevice.DESKTOP;
    const isTabletDevice = () => screenDevice === ScreenDevice.TABLET;
    const isMobileDevice = () => screenDevice === ScreenDevice.MOBILE;

    useEffect(() => {
        // Attach event listener on resize; run once
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    return {
        isMobileDevice,
        isTabletDevice,
        isDesktopDevice
    };
};

export default useMediaQuery;
