import { useRef } from 'react';
import { isSafari, isMobile as isMobileAgent } from 'react-device-detect';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useVideoPlayer from '../../../hooks/useVideoPlayer';
import './VideoPlayer.scss';

interface VideoPlayerProps {
    /**
     * Video source
     */
    src?: string;
}

/**
 * A custom video player component
 */
function VideoPlayer({ src }: VideoPlayerProps) {
    const { isMobileDevice } = useMediaQuery();
    const videoRef = useRef<HTMLVideoElement>({} as HTMLVideoElement);
    const {
        playerState,
        togglePlay,
        toggleLoop,
        toggleMute,
        enableFullscreen,
        handleOnTimeUpdate,
        changeVideoProgress,
        changeVideoSpeed
    } = useVideoPlayer(videoRef);

    return isMobileAgent ? (
        <div className="video-player">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                className="video-player__video"
                src={src}
                ref={videoRef}
                width={1920}
                height={1080}
                preload="auto"
                onClick={togglePlay}
                onEnded={(!playerState.isLooping && togglePlay) || undefined}
                loop={playerState.isLooping}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
                controls
            />
        </div>
    ) : (
        <div className="video-player">
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                className="video-player__video"
                src={src}
                ref={videoRef}
                width={1920}
                height={1080}
                preload="auto"
                onClick={togglePlay}
                onEnded={(!playerState.isLooping && togglePlay) || undefined}
                loop={playerState.isLooping}
                onTimeUpdate={handleOnTimeUpdate}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
            >
                {!isSafari && <track label="English" kind="subtitles" srcLang="en" default />}
            </video>
            <input
                className="video-player__progress-bar"
                type="range"
                min="0"
                max="100"
                value={playerState.progress}
                onChange={changeVideoProgress}
            />
            <div className="video-player__separator" />
            <div className="video-player__controls">
                <div className="video-player__controls__left">
                    {/* Play button */}
                    <button
                        name="play"
                        className={`bi ${
                            !playerState.isPlaying ? 'bi-play-fill' : 'bi-pause-fill'
                        }`}
                        type="button"
                        onClick={togglePlay}
                    />
                    {/* Loop button */}
                    <button
                        name="loop"
                        className={`bi bi-arrow-repeat ${!playerState.isLooping ? 'bi--off' : ''}`}
                        type="button"
                        onClick={toggleLoop}
                    />
                    {/* Playback speed */}
                    <select name="playspeed" value={playerState.speed} onChange={changeVideoSpeed}>
                        <option value="0.50">0.50x</option>
                        <option value="1">1x</option>
                        <option value="1.25">1.25x</option>
                        <option value="2">2x</option>
                    </select>
                    {/* Mute button */}
                    <button
                        name="mute"
                        className={`bi bi--small ${
                            !playerState.isMuted ? 'bi-volume-up-fill' : 'bi-volume-mute-fill'
                        }`}
                        type="button"
                        onClick={toggleMute}
                    />
                </div>
                <div className="video-player__controls__mid">
                    {/* Timestamp */}
                    <p>
                        <span className="time--current">{`${playerState.currentTime}`} / </span>
                        <span className="time--duration">{`${playerState.duration}`} &nbsp;</span>
                    </p>
                </div>
                <div className="video-player__controls__right">
                    {/* Fullscreen button */}
                    <button
                        name="fullscreen"
                        type="button"
                        className={`bi bi-arrows-fullscreen bi--smaller ${
                            !isMobileDevice() && 'zIndex-4'
                        }`}
                        onClick={enableFullscreen}
                    />
                </div>
            </div>
        </div>
    );
}

export default VideoPlayer;
